import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import CVForm from "../StaticPage/ContactUs/ContactUs"
import parse from 'html-react-parser';
import StaticBannerVideo from "../StaticPage/AreaGuideBanner"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Instagram from "../Instagram"
import logoBlack from "../../images/redbrik-new-logo.svg"
import $ from "jquery"
import SEO from "../seo"
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from "react-share";


const SINGLE_JOB = gql`
query GetserviceBlockContents($URL: String!){
    serviceBlockContents(where:{URL: $URL}){
        id
        contents
        image {
            url
        }
        btn_label
        URL
        title
        Bold_Title
    }
}
`;


function ImageBlockSlideDetailsPage(props) {
   
	const { loading, error, data } = useQuery(SINGLE_JOB, {
        variables: { URL: props.slug }
      });
           
      const shareurl = typeof window !== 'undefined' ? window.location.href : ''
      const [Shareicons, setShareicons] = React.useState(false);
  
      const openShareicons = () => {
          setShareicons(true);
          if (Shareicons === true) {
              setShareicons(false);
          }
      }  

      const getBreadcrumb = (list) => {
        let bread = [
            { Title: "lifestyle", Slug: "lifestyle/" }]
        if (list) {
            bread.push({ Title: list[0].title, Slug: `/${`URL`}` })
        }
        return bread;
    }

   if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack} alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
    )
   
    return (
        <React.Fragment>
            <div className="news-details">
                <Header />

                <div className="collection-landing news-details">
                  
                    
                    <Breadcrumb staticList={getBreadcrumb(data?.serviceBlockContents)} />
                    {data.serviceBlockContents.map((data, index) => {                  
                       
                        return (
                            <Container className="content">
                                <SEO
                                    title={`${data.title}`}
                                    description={`${data?.contents}`}
                                />

                                <Row>
                                    <Col lg={1}>
                                    </Col>
                                    <Col lg={10}>
                                        <div className="heading">                                          
                                            <div className="main-title">
                                                <h1>{data.title} {data?.Bold_Title}</h1>
                                                <Row>
                                                    <Col lg={8}>
                                                        <div className="social">
                                                        <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share this post</a>
                                                        {Shareicons &&
                                                            <div onClick={openShareicons} className="social-share">
                                                                <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                                                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                                                </FacebookShareButton>
                                                                <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                                                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                                                </TwitterShareButton>

                                                            </div>
                                                        }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {data.image &&
                                            <div className="news-main-banner">
                                                <img src={data.image.url} alt={data.title} />
                                                  
                                            </div>
                                        }
                                        {data.contents &&
                                            <div className="row">
                                                <div className="static-content col-lg-12">
                                                    {parse(data.contents)}                                                    
                                                </div>
                                            </div>
                                        }                                     
                                    </Col>
                                    <Col lg={1}>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })}    



                </div>
               
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default ImageBlockSlideDetailsPage;
